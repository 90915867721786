@import '../colors.scss';

.certification-name {
  font-weight: 300;
  font-size: 14pt;
}

.certification-issued {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}

.certification-expires {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-red;
}

.cme-summary-count {
  font-weight: 200;
  font-size: 48pt;
  color: #000000;
}

.cme-summary-label {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}

.blue-bg {
  background-color: blue;
}

.cme-category-title {
  font-weight: 500;
  font-size: 14pt;
  color: $hq-text-muted;
}

.cme-subcategory-title {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}

.cme-none {
  font-size: 12pt;
  color: $hq-text-muted;
}

.section-header {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.divider-color {
  color: $hq-text-light;
}
