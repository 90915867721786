@import '../colors.scss';

.navbar {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background-color: $hq-red;
}

.navbar-logo {
  height: 30px;
}

a:hover > span {
  color: #FFFFFF;
}

.light-color {
  color: #FFFFFF;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: $hq-text-muted;
  background-color: $hq-off-white;
  font-size: 12px;
  overflow: hidden;
}

.payment-card {
  border: 2px solid $hq-off-white;
  border-radius: 4px;
  padding: 10px;
}

.flag {
  color: $hq-text-muted;
  height: 14px;
}

.user-menu {
  height: 32px;
}

.tab-bar {
  display: none;
  background-color: $hq-off-white;
  height: 60px;
}

.tab-icon {
  height: 30px;
}

.tab-container {
  height: 60px;
}

.nav-link {
  color: #FFFFFF;
  font-weight: 500;
}

a:hover {
  color: #FFFFFF;
}

.nav-inactive {
  font-weight: 300;
}

.active {
  color: #FFFFFF;
}

@media only screen and (max-width: 991px) {
  .tab-bar {
    display: block;
  }

  .nav {
    display: none;
  }

  .tab-inactive {
    filter: brightness(0) invert(0.65);
  }
}
