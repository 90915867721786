@import './colors.scss';
@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: auto !important;
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  font-family: 'Inter', sans-serif;
}

.form-label {
  color: $hq-text-muted;
  font-weight: 600;
  font-size: 12px;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.nav-link:hover {
  color: #FFFFFF;
}

.link {
  font-size: 13px;
}

.link:hover {
  color: $hq-red;
}

.click-pointer {
  cursor: pointer;
}

.small-text {
  color: $hq-text-muted;
  font-size: 12px;
}

.modal-header {
  background-color: $hq-off-white;
  border: 0;
}

.modal-footer {
  background-color: $hq-off-white;
  border: 0;
}

.list-item {
  border-color: #f1f1f1;
  background-color: transparent;
}

.btn-container-sm {
  border-bottom: 1px solid $hq-off-white;
}

.list-item-selected {
  background-color: rgba($color: #000000, $alpha: 0.02);
}

.margin-row-lg {
  margin-top: ($spacer * 1.5) !important;
}

.form-control {
  min-height: 40px;
}

.full-tab {
  border-radius: 0;
  border-width: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.img-rotate {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.certificate-page {
  padding-top: 100pt;
}

.cover-sheet {
  min-height: 792pt;
  page-break-after: always;
}

.full-page {
  height: 792pt;
}

.break-avoid {
  page-break-inside: avoid;
}

@media only screen and (max-width: 991px) {
  .modal-header {
    background-color: $hq-red;
    border: 0;
  }

  .modal-title {
    color: #FFFFFF;
  }

  .btn-close {
    opacity: 1;
    color: #FFFFFF;
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .list-item {
    min-height: 60px;
  }

  .margin-top-sm {
    margin-top: ($spacer * 1.5) !important;
  }

  .margin-top-col-sm {
    margin-top: ($spacer * 0.5) !important;
  }
  
  .margin-row-lg {
    margin-top: 0 !important;
  }
}

@media all and (display-mode: standalone) {
  .standalone-bottom {
    padding-bottom: 60pt;
  }

  .standalone-list {
    margin-bottom: 60pt !important;
  }

  .modal-bottom {
    padding-bottom: 24pt;
  }
}