@import '../colors.scss';

.incident-summary {
  font-weight: 300;
  font-size: 14pt;
}

.incident-location {
  font-weight: 400;
  font-size: 12pt;
  color: $hq-text-muted;
}

.incident-dispatched {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}
