@import '../colors.scss';

.subscription-name {
  font-weight: 300;
  font-size: 14pt;
}

.subscription-expires {
  font-weight: 500;
  font-size: 10pt;
  color: $hq-text-muted;
}

.subscription-renew {
  font-weight: 500;
  font-size: 10pt;
  color: $hq-red;
}

.agency-name {
  font-weight: 300;
  font-size: 14pt;
}

.agency-role {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}

.top-row-height {
  min-height: 190px;
}

.top-row-card {
  height: 100%;
}

.account-info-name {
  font-weight: 300;
  font-size: 14pt;
}

.account-info-email {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}

.address-title {
  font-weight: 500;
  font-size: 10pt;
  color: $hq-text-muted;
}

.promo-code-text {
  font-family: monospace;
  font-size: 16pt;
}