@import '../colors.scss';

.dashboard-item-value {
  font-size: 64pt;
  font-weight: 300;
}

.template-name {
  font-weight: 300;
  font-size: 14pt;
}

.template-style {
  font-weight: 500;
  font-size: 12pt;
  color: $hq-text-muted;
}
