/**
 * Color definitions
 */
$hq-red: #CE2029;
$hq-yellow: #FDF030;
$hq-off-white: #F1F1F1;
$hq-text-light: #C1C1C1;
$hq-text-muted: #6C757D;

$hq-background: #FFFFFF;
$hq-background-dark: #002B36;

$primary: $hq-red;
$secondary: $hq-off-white;
$warning: $hq-yellow;
$light: #ECECEC;

$input-border-color: $hq-off-white;
$input-placeholder-color: $hq-text-light;

$card-border-color: $hq-off-white;
