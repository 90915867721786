@import '../colors.scss';

.benefits-header {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: $hq-text-muted;
}

.benefit {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.discount-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.icon-color {
  color: $hq-red;
}

.subscribe-btn {
  width: 50%;
}

@media only screen and (max-width: 991px) {
  .subscribe-btn {
    width: 100%;
  }
}
