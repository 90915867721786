@import '../colors.scss';

.header-section {
  background-color: $hq-red;
  width: auto;
  height: 600px;
}

.header-logo {
  height: 160px;
}

.header-brand {
  font-family: 'Inter', sans-serif;
  font-size: 42pt;
  font-weight: 200;
  color: #FFFFFF;
}

.btn-warning {
  color: $hq-red;
  font-weight: 500;
}

.btn-secondary {
  color: $hq-red;
  font-weight: 500;
}

.header-tagline {
  font-family: 'Inter', sans-serif;
  font-size: 16pt;
  font-weight: 300;
  color: #FFFFFF;
}

.screenshots {
  height: 575px;
}

.hero-section-icon {
  height: 28px;
}

.hero-section-header {
  font-size: 16pt;
  font-weight: 600;
  color: $hq-red;
}

.price-card {
  background-color: $hq-off-white;
}

.section-header-large {
  font-size: 28pt;
  font-weight: 600;
  color: $hq-red;
}
.landing-price {
  font-size: 64pt;
  font-weight: 600;
  color: $hq-red;
}

.landing-price-label {
  font-size: 18pt;
  font-weight: 600;
  color: $hq-red;
}

.landing-price-tagline {
  font-size: 10pt;
  font-weight: 400;
  color: $hq-text-muted;
}

.screenshot-group {
  width: 800px;
}

.helmet-logo {
  height: 250px;
}

.helmet-headline {
  font-size: 48pt;
  font-weight: 600;
  color: #ffffff;
}

.helmet-subheadline {
  font-size: 36pt;
  font-weight: 600;
  color: #ffffff;
}

.helmet-header-section {
  background-color: $hq-red;
  width: auto;
  height: 620px;
}

.helmet-img {
  height: 400px;
}

.helmet-header-text {
  font-size: 14pt;
  font-weight: 400;
  color: #ffffff;
}

.helmet-body-text {
  font-size: 14pt;
  font-weight: 400;
  color: #000000;
}

.promo-code-tagline {
  font-size: 10pt;
  font-weight: 600;
  color: $hq-text-muted;
}

@media only screen and (max-width: 991px) {
  .header-logo {
    height: 48px;
  }

  .header-brand {
    font-size: 21pt;
  }

  .header-section {
    height: 840px;
  }

  .header-tagline {
    font-size: 16pt;
    font-weight: 400;
  }

  .screenshots {
    height: 400px;
  }

  .screenshot-group {
    width: 90%;
  }

  .helmet-header-section {
    height: 480px;
  }

  .helmet-logo {
    height: 140px;
  }

  .helmet-headline {
    font-size: 21pt;
  }

  .helmet-subheadline {
    font-size: 16pt;
  }

  .helmet-promo-code {
    font-weight: 600;
  }

  .helmet-img {
    height: 340px;
  }
}
